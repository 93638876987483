
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Dora css */

p{
  font-family: 'Nunito Sans', sans-serif;
  color: #000;
  line-height: 150% !important;
}

h1{
  font-family: 'IBM Plex Mono', monospace;
  font-size: 48px;
  line-height: 150%;
}

h2{
  font-family: 'IBM Plex Mono', monospace;
  font-size: 48px;
  line-height: 150%;
}

h3{
  font-family: 'Nunito Sans', sans-serif;
  color: #000;
  line-height: 150%;
  font-size: 24px;
}

.w-hero{
  width: 810px;
}

.w-content{
  width: 330px;
}

.hero-text{
  background-image: linear-gradient(to right, #61E2A4, #43A2B3);
}

.text-agent{
  font-family: 'IBM Plex Mono', monospace;
  font-size: 25px;
  line-height: 130%;
}

.bg-blue{
  background: rgba(97, 226, 164, 0.1);
}

.text-gray{
  color: #302B2E;
}

.h-s{
  height: 28rem;
}

.img-s{
  left: 50%;
  transform: translate(-50%, 0%);
}

.bg-gray{
  background-color: #F7F7F7;
}

.bg-insta{
  background-color: #E1306C;
}

.bg-fb{
  background-color: #3B5998;
}

.bg-twitter{
  background-color: #1DA1F2;
}

.input-border{
  border: 0.5px solid #D4D4D4;
}

.bg-brown{
  background-color: #444444;
}

.bg-mate{
  background-color: #302B2E;
}

.text-mate{
  color: rgba(0, 0, 0, 0.8);
}

.tracking-widestt {
  letter-spacing: 0.2em;
}

.fadein {
  animation: fadein 0.3s linear;
}

.z-minus{
  z-index: -1;
}

.dimmer
{
    background:#000;
    opacity:0.5;
    position:fixed; /* important to use fixed, not absolute */
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:10; /* may not be necessary */
}

.messageBox {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.h-arc{
  height: calc(100% - 100px);
}

.h-arc2{
  height: calc(100% - 100px);
}

.h-fit{
  height: fit-content;
}

@media only screen and (max-width:786px) {
  h1{
    font-size: 30px;
    line-height: 150%;
  }
  
  h2{
    font-size: 20px;
  }
  
  h3{
    font-size: 16px;
  }
  .text-agent{
    font-size: 17px;
  }
  .w-content{
    width: 100%;
  }

}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}